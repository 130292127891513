export const getApiKey = (): string => {
  // return '0vcgrs5d4uamqnhnju15cjwgqlck6qz3npqhg5eszeuixybw'
  // return 'x4ekaymbtv68hw3kr6rchkqzwb3hqb2q5sbzt688421wwsro' sorin.puncea@teamlearning.ai
  return '1keouqou4p61kqfexekqpd3lw7odmp3asxvos8n8fvnker1x' // sifihi1217@mposhop.com
}

export const resizeFont = (targetRef) => {
  // if (targetRef.value.scrollHeight > targetRef.value.offsetHeight) {
  //   let downsizingTry = 0
  //   while ((targetRef.value.scrollHeight > targetRef.value.offsetHeight) && parseFloat(window.getComputedStyle(targetRef.value).fontSize) > 6 && downsizingTry < 500) {
  //     targetRef.value.style.fontSize = (parseFloat(window.getComputedStyle(targetRef.value).fontSize) - 0.5) + 'px'
  //     // console.log('Downsized to: ', parseFloat(window.getComputedStyle(targetRef.value).fontSize) + 'px')
  //     downsizingTry++
  //   }
  // }
  //
  // if (targetRef.value.scrollHeight === targetRef.value.offsetHeight && parseFloat(window.getComputedStyle(targetRef.value).fontSize) < 42) {
  //   let upsizingTry = 0
  //   let upsizingFailed = false
  //
  //   while (targetRef.value.scrollHeight === targetRef.value.offsetHeight && parseFloat(window.getComputedStyle(targetRef.value).fontSize) < 42 && !upsizingFailed && upsizingTry < 500) {
  //     targetRef.value.style.fontSize = (parseFloat(window.getComputedStyle(targetRef.value).fontSize) + 0.5) + 'px'
  //
  //     if (targetRef.value.scrollHeight > targetRef.value.offsetHeight && parseFloat(window.getComputedStyle(targetRef.value).fontSize) > 6) {
  //       // console.log('Scroll detected, reverting...')
  //       upsizingFailed = true
  //       while ((targetRef.value.scrollHeight > targetRef.value.offsetHeight) && parseFloat(window.getComputedStyle(targetRef.value).fontSize) > 6) {
  //         targetRef.value.style.fontSize = (parseFloat(window.getComputedStyle(targetRef.value).fontSize) - 0.5) + 'px'
  //         // console.log('Downsized to: ', parseFloat(window.getComputedStyle(targetRef.value).fontSize) + 'px')
  //       }
  //       // console.log('Upsizing finished, upsized to: ', parseFloat(window.getComputedStyle(targetRef.value).fontSize) + 'px')
  //       upsizingTry++
  //     }
  //   }
  // }
}
